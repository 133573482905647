<div id="sidebar">
  <mat-nav-list>
    <a
      mat-list-item
      [routerLinkActive]="'active'"
      routerLink="/admin/dashboard"
      *ngIf="checkRoles('/admin/users')"
    >
      <mat-icon class="sidenav-icon">dashboard</mat-icon>
      Analitika
    </a>
    <ng-container *ngIf="checkRoles('/admin/users')">
      <mat-accordion class="sidebar-dropdown">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="sidenav-icon">account_box</mat-icon>
              Administracija
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/users"
          >
            <mat-icon class="sidenav-icon">account_box</mat-icon>
            Korisnici
          </a>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/user-roles"
          >
            <mat-icon class="sidenav-icon">account_box</mat-icon>
            Role i permisije
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <a
      mat-list-item
      [routerLinkActive]="'active'"
      routerLink="/admin/companies"
      *ngIf="checkRoles('/admin/companies')"
    >
      <mat-icon class="sidenav-icon">home_work</mat-icon>
      Firme
    </a>

    <a
      mat-list-item
      [routerLinkActive]="'active'"
      *ngIf="checkRoles('/admin/branches')"
      routerLink="/admin/branches"
    >
      <mat-icon class="sidenav-icon">device_hub</mat-icon>
      Lokacije/hot spotovi
    </a>
    <ng-container
      *ngIf="checkRoles('/admin/cities') || checkRoles('/admin/countries')"
    >
      <mat-accordion class="sidebar-dropdown">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="sidenav-icon">apartment</mat-icon>
              Geo lokacije
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/countries"
            *ngIf="checkRoles('/admin/countries')"
          >
            <mat-icon class="sidenav-icon">apartment</mat-icon>
            Države
          </a>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/cities"
            *ngIf="checkRoles('/admin/cities')"
          >
            <mat-icon class="sidenav-icon">apartment</mat-icon>
            Gradovi
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-container
      *ngIf="
        checkRoles('/admin/campaigns') ||
        checkRoles('/admin/advertisements') ||
        checkRoles('/admin/tags')
      "
    >
      <mat-accordion class="sidebar-dropdown">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="sidenav-icon">style</mat-icon>
              Oglašavanje
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            *ngIf="checkRoles('/admin/campaigns')"
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/campaigns"
          >
            <mat-icon class="sidenav-icon">style</mat-icon>
            Kampanje
          </a>
          <a
            *ngIf="checkRoles('/admin/advertisements')"
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/advertisements"
          >
            <mat-icon class="sidenav-icon">style</mat-icon>
            Reklame
          </a>
          <a
            *ngIf="checkRoles('/admin/tags')"
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/tags"
          >
            <mat-icon class="sidenav-icon">tag</mat-icon>
            Kategorije (tagovi)
          </a>
          <a
            *ngIf="checkRoles('/admin/branding-buttons')"
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/branding-buttons"
          >
            <mat-icon class="sidenav-icon">collections</mat-icon>
            Branding button
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <ng-container
      *ngIf="checkRoles('/admin/routers')"
    >
      <mat-accordion class="sidebar-dropdown">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="sidenav-icon">routers</mat-icon>
              Ruteri
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/routers"
          >
            <mat-icon class="sidenav-icon">list</mat-icon>
            Lista
          </a>
          <a
            mat-list-item
            [routerLinkActive]="'active'"
            routerLink="/admin/routers/analytics"
          >
            <mat-icon class="sidenav-icon">analytics</mat-icon>
            Statistika pregleda
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <!-- <ng-container>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Test dropdown
            </mat-panel-title>
          </mat-expansion-panel-header>
          x
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container> -->
  </mat-nav-list>
</div>
